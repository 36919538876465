<template>
  <b-input-group>
    <flat-pickr v-model="rangeDate" class="form-control" :config="datePickerOptions.datePickerOptions"
      :placeholder="$t('common.periods.placeholder')" />
    <b-input-group-append>
      <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" right variant="outline-primary" split
        v-b-tooltip.hover.v-primary :title="$t('common.tooltips.period_select')" @click="refresh"
        split-class="pt-0 pb-0">
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'search']" fixed-width />
        </template>
        <b-dropdown-item v-for="period in options.periods" :key="period.index"
          @click="setPeriod(period)">{{ $t('common.periods.' + period) }}</b-dropdown-item>
      </b-dropdown>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  format, sub, startOfMonth, endOfMonth, startOfYear, endOfYear,
} from 'date-fns'
import flatPickr from 'vue-flatpickr-component'
import datePickerOptions from './_datePickerOptions'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    flatPickr,
  },
  props: {
    field: {},
    options: {
      type: Object,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      rangeDate: this.field,
      datePickerOptions,
    }
  },
  methods: {
    refresh() {
      this.$emit('update:field', this.rangeDate)
      setTimeout(() => {
        this.$emit('onSelected')
      }, 300)
    },
    setPeriod(period) {
      let startDate = new Date()
      let endDate = new Date()
      const periodAux = period.split('-')
      if (periodAux.length === 1) {
        periodAux.push(0)
      }
      switch (periodAux[0]) {
        case 'd':
          startDate = sub(startDate, { days: periodAux[1] })
          break
        case 'm':
          startDate = startOfMonth(sub(startDate, { months: periodAux[1] }))
          endDate = endOfMonth(startDate)
          break
        case 'y':
          startDate = startOfYear(sub(startDate, { years: periodAux[1] }))
          endDate = endOfYear(startDate)
          break
        default:
          startDate = new Date()
      }
      if (endDate > new Date()) {
        endDate = new Date()
      }
      this.rangeDate = [
        format(startDate, 'dd/MM/yyyy'),
        format(endDate, 'dd/MM/yyyy'),
      ]
      this.$emit('update:field', this.rangeDate)
      this.refresh()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
