import meliClient from './core/client'

export default {
  getItemsSearch(accessToken, sellerId, params) {
    const body = {
      method: 'GET',
      url: 'users/' + sellerId + '/items/search', // eslint-disable-line prefer-template
      parameters: {
        access_token: accessToken,
        ...params,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getActiveItemsSimple(accessToken, sellerId) {
    const params = {
      status: 'active',
      include_filters: 'true',
    }
    return this.getItemsSearch(accessToken, sellerId, params)
  },
  getItem(accessToken, itemId, params) {
    const body = {
      method: 'GET',
      url: 'items/' + itemId, // eslint-disable-line prefer-template
      parameters: {
        access_token: accessToken,
        ...params,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getItemsMultiget(accessToken, itemId, params) {
    const body = {
      method: 'GET',
      url: 'items',
      parameters: {
        access_token: accessToken,
        ...params,
      },
    }
    return meliClient.callMeliProcess(body)
  },
  getItems(accessToken, itemIds, attributes) {
    const params = {
      ids: itemIds.join(','),
      attributes,
    }
    return this.getItemsMultiget(accessToken, '', params)
  },
}
