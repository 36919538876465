import { format } from 'date-fns'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  datePickerOptions: {
    mode: 'range',
    allowInput: true,
    wrap: true,
    dateFormat: 'd/m/Y',
    defaultDate: [
      format(new Date(), 'dd/MM/yyyy'),
      format(new Date(), 'dd/MM/yyyy'),
    ],
    maxDate: format(new Date(), 'dd/MM/yyyy'),
    locale: Portuguese,
  },
}
