<template>
  <b-card no-body>
    <HTMLTitle :title="$t('components.cards.meli_reports.page_title')"></HTMLTitle>

    <card-header :title="$t('components.cards.meli_reports.page_title')"
      :id="'meliReports'"
      :icon="['fas', 'list-alt']"
      :subTitle="(selectedAccount && selectedAccount.nickname) || $t('components.cards.common.select_account')"
      :description="$t('components.cards.meli_reports.page_info')"></card-header>

    <b-card-body class="p-0">
      <b-tabs content-class="pl-1 pr-1 pl-sm-2 pr-sm-2" nav-class="pl-1 pr-1 pl-sm-2 pr-sm-2" justified>

        <b-tab v-for="tab in tabs" :key="tab.index">
          <template #title>
            <font-awesome-icon :icon="tab.icon" class="m-0" fixed-width />
            <span class="font-weight-bold d-none d-md-inline ml-50">{{
              $t(tab.title)}}</span>
          </template>
          <component :is="tab.component" :options="options" />
        </b-tab>

      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BTabs, BTab,
} from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import CardReportSalesByListing from '@/layouts/components/cards/meli/analytics/sales/CardReportSalesByListing.vue'
import CardReportSalesBySku from '@/layouts/components/cards/meli/analytics/sales/CardReportSalesBySku.vue'
import CardReportSalesByState from '@/layouts/components/cards/meli/analytics/sales/CardReportSalesByState.vue'
import CardReportSalesByLogistic from '@/layouts/components/cards/meli/analytics/sales/CardReportSalesByLogistic.vue'
import CardReportSalesByHour from '@/layouts/components/cards/meli/analytics/sales/CardReportSalesByHour.vue'

export default {
  components: {
    HTMLTitle,
    CardHeader,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    CardReportSalesByListing,
    CardReportSalesBySku,
    CardReportSalesByState,
    CardReportSalesByLogistic,
    CardReportSalesByHour,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      tabs: [
        {
          title: 'components.cards.meli_reports.by_listing',
          icon: ['fas', 'tag'],
          component: 'CardReportSalesByListing',
        },
        {
          title: 'components.cards.meli_reports.by_sku',
          icon: ['fas', 'barcode'],
          component: 'CardReportSalesBySku',
        },
        {
          title: 'components.cards.meli_reports.by_state',
          icon: ['fas', 'map-marker-alt'],
          component: 'CardReportSalesByState',
        },
        {
          title: 'components.cards.meli_reports.by_logistic',
          icon: ['fas', 'truck'],
          component: 'CardReportSalesByLogistic',
        },
        {
          title: 'components.cards.meli_reports.by_hour',
          icon: ['fas', 'clock'],
          component: 'CardReportSalesByHour',
        },
      ],
      selectedAccount: null,
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>
