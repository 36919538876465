<template>
  <b-card no-body :bg-variant="bgVariant" :border-variant="borderVariant" :text-variant="textVariant" class="mb-1">
    <b-card-body class="p-1">
      <div class="truncate">
        <h4 class="mb-0 font-weight-bolder" :class="`text-${textVariant}`">
          {{ statistic }}
        </h4>
        <b-card-text class="font-small-3">{{ statisticDescription }}</b-card-text>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
  },
  props: {
    bgVariant: {
      type: String,
    },
    borderVariant: {
      type: String,
    },
    textVariant: {
      type: String,
    },
    statistic: {
      type: [Number, String],
    },
    statisticDescription: {
      type: String,
      default: '',
    },
  },
}
</script>
