<template>
  <b-card no-body style="min-height:400px;">
    <b-card-header v-if="options.showTitle || options.showAccount" class="p-1">
      <div>
        <b-card-title v-if="options.showTitle" class="mb-1">
          {{ $t('components.cards.meli_reports.sales_by_sku_page_title')}}
        </b-card-title>
        <b-card-sub-title v-if="options.showAccount && !account">
          <span v-if="selectedAccount">{{ selectedAccount.nickname }}</span>
          <span v-else>{{ $t('components.cards.common.select_account') }}</span>
        </b-card-sub-title>
      </div>
      <div class="d-flex align-items-center">
        <simple-account-selector v-if="!inherit && !account" @change="changeSelectedAccount">
        </simple-account-selector>
      </div>
    </b-card-header>

    <b-card-body class="p-0">
      <b-row>
        <b-col sm="10" md="10" lg="6" xl="4" class="mb-1">
          <date-range-selector :field.sync="rangeDate" :options="cardMeliReportSalesBySkuOptions.options"
            @onSelected="getReportData" />
        </b-col>
        <b-col class="mb-1">
          <b-button-toolbar>
            <b-button-group>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                @click="downloadFile('xlsx')" v-b-tooltip.hover.v-primary :title="$t('common.tooltips.download')">
                <font-awesome-icon :icon="['fas', 'download']" fixed-width />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                @click.prevent="cardMeliReportSalesBySkuOptions.options.showSummary = !cardMeliReportSalesBySkuOptions.options.showSummary"
                v-b-tooltip.hover.v-primary :title="$t('common.tooltips.toggle_summary')">
                <font-awesome-icon v-if="cardMeliReportSalesBySkuOptions.options.showSummary"
                  :icon="['fas', 'th-large']" fixed-width />
                <font-awesome-icon v-else :icon="['fas', 'th']" fixed-width />
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                @click="cardMeliReportSalesBySkuOptions.options.tableStacked = !cardMeliReportSalesBySkuOptions.options.tableStacked"
                v-b-tooltip.hover.v-primary :title="$t('common.tooltips.toggle_view')">
                <font-awesome-icon v-if="cardMeliReportSalesBySkuOptions.options.tableStacked"
                  :icon="['fas', 'grip-horizontal']" fixed-width />
                <font-awesome-icon v-else :icon="['fas', 'grip-vertical']" fixed-width />
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </b-col>
      </b-row>

      <table-info :showLoading="formSubmitted" :showNotFound="notFound"></table-info>

      <summary-card v-if="!notFound && cardMeliReportSalesBySkuOptions.options.showSummary" :summary="summary"
        :showExtra="cardMeliReportSalesBySkuOptions.options.showSummary" />

      <b-pagination v-if="!notFound && (pagination.totalRows > pagination.perPage)" v-model="pagination.currentPage"
        hide-goto-end-buttons :total-rows="pagination.totalRows" :per-page="pagination.perPage" align="right" />

      <b-table v-if="!notFound" responsive bordered :stacked="cardMeliReportSalesBySkuOptions.options.tableStacked"
        :items="results" :fields="fields" :per-page="pagination.perPage" :current-page="pagination.currentPage"
        :sort-by.sync="pagination.sortBy" :sort-desc.sync="pagination.sortDesc" class="mb-0">

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <br />
      <b-pagination v-if="(pagination.totalRows > pagination.perPage) && (y > 250)" v-model="pagination.currentPage"
        hide-goto-end-buttons :total-rows="pagination.totalRows" :per-page="pagination.perPage" align="right" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable, BRow, BCol, BButton, BButtonToolbar, BButtonGroup,
  VBTooltip, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Crypt from '@core-md/utils/crypt'
import DateRangeSelector from '@core-md/components/DateRangeSelector/DateRangeSelector.vue'
import TableInfo from '@core-md/components/TableInfo.vue'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import stringHelper from '@core-md/mixins/stringHelper'
import dateHelper from '@core-md/mixins/dateHelper'
import Excel from '@core-md/utils/excel'
import { $themeConfig } from '@themeConfig'
import { useWindowScroll } from '@vueuse/core'
import SummaryCard from './CardReportSalesSummary.vue'
import cardMeliReportSalesBySkuOptions from './_cardMeliReportSalesBySkuOptions'
import simpleAccountSelector from '../../../components/SimpleAccountSelector.vue'
import meliReports from '@/services/meli/reports/client'

export default {
  components: {
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BRow,
    BCol,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BPagination,
    simpleAccountSelector,
    SummaryCard,
    DateRangeSelector,
    TableInfo,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    variants,
    handleAxiosException,
    stringHelper,
    dateHelper,
  ],
  data() {
    return {
      selectedAccount: null,
      cardMeliReportSalesBySkuOptions,
      fields: [
        {
          key: 'seller_custom_field',
          label: this.$i18n.t('components.cards.meli_reports.sku'),
          sortable: true,
          tdClass: 'p-1',
        },
        {
          key: 'sales_qty',
          label: this.$i18n.t('components.cards.meli_reports.sales_qty'),
          sortable: true,
          thClass: 'text-center',
          class: 'text-right',
        },
        {
          key: 'sales_amount',
          label: this.$i18n.t('components.cards.meli_reports.sales_amount'),
          sortable: true,
          thClass: 'text-center',
          class: 'text-right',
          formatter: value => this.formatNumber(value, 2),
        },
        {
          key: 'fee',
          label: this.$i18n.t('components.cards.meli_reports.fee'),
          sortable: true,
          thClass: 'text-center',
          class: 'text-right',
          formatter: value => this.formatNumber(value, 2),
        },
        {
          key: 'shipping_cost',
          label: this.$i18n.t('components.cards.meli_reports.shipping_cost'),
          sortable: true,
          thClass: 'text-center',
          class: 'text-right',
          formatter: value => this.formatNumber(value, 2),
        },
        {
          key: 'net_value',
          label: this.$i18n.t('components.cards.meli_reports.net_value'),
          sortable: true,
          thClass: 'text-center',
          class: 'text-right',
          formatter: value => this.formatNumber(value, 2),
        },
      ],
      results: [],
      summary: {},
      pagination: {
        currentPage: 1,
        perPage: 100,
        totalRows: 0,
        sortBy: 'sales_amount',
        sortDesc: true,
      },
      itemIds: [],
      rangeDate: null,
      formSubmitted: false,
      unWatchSelectedAccount: null,
    }
  },
  computed: {
    accountLoaded() {
      return this.selectedAccount.loading >= 100
    },
    notFound() {
      return this.pagination.totalRows === 0
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    const { y } = useWindowScroll()

    return {
      y,
      spinnerType,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
          this.getReportData()
        }
      })
    }
    this.getReportData()
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
  methods: {
    getReportData() {
      if (this.selectedAccount) {
        this.results = []
        this.pagination.totalRows = 0
        this.pagination.currentPage = 1
        this.formSubmitted = true
        this.series = []
        const dateRange = this.getDatesFromRange(this.rangeDate)
        const request = {
          sellerId: this.selectedAccount.seller_id,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }
        meliReports.getSalesBySku(request).then(response => {
          if (response.status === 200) {
            const result = Crypt.decrypt(response.data.result)
            this.results = result
            this.pagination.totalRows = this.results.length
            this.itemIds = []
            this.results.forEach(element => {
              element.seller_custom_field = element.seller_custom_field || this.$i18n.t('components.cards.meli_reports.no_information') // eslint-disable-line no-param-reassign
              element.net_value = parseFloat(element.sales_amount) - (parseFloat(element.fee) + parseFloat(element.shipping_cost)) // eslint-disable-line no-param-reassign
            })
            this.calculateSummary()
          }
          this.formSubmitted = false
        }).catch(data => {
          console.log(data)
          this.formSubmitted = false
        })
      }
    },
    calculateSummary() {
      const summary = {
        gross: 0,
        fee: 0,
        shipping: 0,
        qty: 0,
        net: 0,
        averageGrossTicket: 0,
        averageNetTicket: 0,
        averageFee: 0,
        averageShipping: 0,
        feePercentage: 0,
        qtyChargedShipping: 0,
      }
      this.results.forEach(element => {
        summary.gross += parseFloat(element.sales_amount)
        summary.fee += parseFloat(element.fee)
        summary.shipping += parseFloat(element.shipping_cost)
        summary.qty += parseFloat(element.sales_qty)
        // if (parseFloat(element.shipping_cost) > 0) {
        // summary.qtyChargedShipping += parseFloat(element.sales_qty)
        // }
      })
      if (summary.qty > 0) {
        summary.net = summary.gross - (summary.fee + summary.shipping)
        summary.averageGrossTicket = summary.gross / summary.qty
        summary.averageNetTicket = summary.net / summary.qty
        summary.averageFee = summary.fee / summary.qty
        summary.totalAverageShipping = summary.shipping / summary.qty
        summary.chargedAverageShipping = null // summary.shipping / summary.qtyChargedShipping
        summary.feePercentage = (summary.fee / summary.gross) * 100
      }
      this.summary = summary
    },
    changeSelectedAccount(selected) {
      this.selectedAccount = selected
      this.getReportData()
    },
    downloadFile(fileFormat) {
      const data = {
        worksheetName: this.$i18n.t('components.cards.meli_reports.sales_by_sku_sheet_name'),
        fileName: this.$i18n.t('components.cards.meli_reports.sales_by_sku_file_name') + fileFormat, // eslint-disable-line
        format: fileFormat,
      }
      const fontStyle = {
        name: 'Calibri', size: 11,
      }
      data.columns = [
        { header: this.$i18n.t('components.cards.meli_reports.sku'), width: 15, style: { font: fontStyle } },
        { header: this.$i18n.t('components.cards.meli_reports.sales_qty'), width: 15, style: { font: fontStyle, numFmt: '#,##0' } },
        { header: this.$i18n.t('components.cards.meli_reports.sales_amount'), width: 15, style: { font: fontStyle, numFmt: '0.00' } },
        { header: this.$i18n.t('components.cards.meli_reports.fee_percentage'), width: 15, style: { font: fontStyle, numFmt: '0.00' } },
        { header: this.$i18n.t('components.cards.meli_reports.fee'), width: 15, style: { font: fontStyle, numFmt: '0.00' } },
        { header: this.$i18n.t('components.cards.meli_reports.shipping_cost'), width: 15, style: { font: fontStyle, numFmt: '0.00' } },
        { header: this.$i18n.t('components.cards.meli_reports.net_value'), width: 15, style: { font: fontStyle, numFmt: '0.00' } },
      ]
      data.rows = this.results.map(t => {
        const r = [
          t.seller_custom_field || '',
          parseFloat(t.sales_qty),
          parseFloat(t.sales_amount),
          (parseFloat(t.fee) / parseFloat(t.sales_amount)) * 100,
          parseFloat(t.fee),
          parseFloat(t.shipping_cost),
          parseFloat(t.net_value),
        ]
        return r
      })

      data.summary = []
      data.summary.push([
        this.$i18n.t('components.cards.meli_reports.totals'),
        this.summary.qty,
        this.summary.gross,
        this.summary.feePercentage,
        this.summary.fee,
        this.summary.shipping,
        this.summary.net,
      ])
      data.summary.push([
        this.$i18n.t('components.cards.meli_reports.averages'),
        '',
        this.summary.averageGrossTicket,
        '',
        this.summary.averageFee,
        this.summary.totalAverageShipping,
        this.summary.averageNetTicket,
      ])
      Excel.downloadFile(data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
