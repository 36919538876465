export default {
  options: {
    tableStacked: false,
    showSummary: true,
    showImages: false,
    periods: [
      'd', 'd-7', 'm', 'm-1', 'y', 'y-1',
    ],
  },
}
