export default {
  // Endpoints
  salesByListingEndpoint: '/meliReports/salesByListing.json',
  salesBySkuEndpoint: '/meliReports/salesBySku.json',
  salesByStateEndpoint: '/meliReports/salesByState.json',
  salesByLogisticEndpoint: '/meliReports/salesByLogistic.json',
  salesByHourEndpoint: '/meliReports/salesByHour.json',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'auth_token',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData',
}
